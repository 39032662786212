/* logo pic on top of page */
.logo-pic img {
    width: 20%;
    object-fit: cover;
    right:0;
    margin-left: 80%;
    margin-top: -6%;
    /* margin-bottom: 0px; */
}


/* navigation bar */
.navbar {
    font-weight:bold;
    width:100%;
}

.nav-link {
    color:#acff07;
    margin-right: 5px;
    margin-left: 10px;
}


.mr-auto{
    margin-left: 30px;
}

.ml-auto {
    margin-left: auto;
    margin-right: 30px;
}


/* website background picture */

body{ 
  background-image: url('./assets/tennisPlayer copy.jpg');
  background-size:cover;
  background-repeat: no-repeat; 
  background-position: center center;
  flex: 1; 
  padding-top: 75px;
}
@media screen and (max-width: 768px) {
/* Adjust background size for smaller screens */
.background-container {
  background-size: contain;
}
}


 /* below are the styling for search form in SearchForm component*/

.w-100{
  margin-top:40px;
}

.tennis-search-form {
    display:flex;
    flex-direction: column;
    align-items:center;
    margin-bottom: 20px;
 

}

.tennis-search-form label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 6px;
    color:#000000;
}


.zip-code-input {
    position: relative;
}

.zip-code-input input[type="number"] {
    background-color: #f1f1f1;
    border:#acff07;
    border-radius: 50px;
    padding: 12px 20px;
    font-size: 16px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}



.tennis-level-select select {
    background-color: #f1f1f1;
    border: #acff07;
    border-radius: 50px;
    padding: 12px 20px;
    font-size: 16px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


 
.tennis-search-button {
    background-color:#255b28;
    border: none;
    border-radius: 50px;
    padding: 14px 20px;
    font-size: 16px;
    color: white;
    cursor:pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    margin-left: 90px;
}
  
.tennis-search-button:hover {
    background-color: #45a049;
}



/* below are the styling for search message in Home component*/

.home-container h2 {
    color:#0b0c0b;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;

}


.no-match-message {
    color: #090808;
    text-align: center;
    font-weight: bold;
    font-size: larger;
  }


/* below are the styling for search result in TennisUser component*/

.tennis-user-card {
    width: 17rem;
    background-color:white;
    border-radius: 10%;
    padding: 20px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content:left;
    position: relative; 
  
}

.user-info {
  text-align: left;
}


.tennis-user-card .user-info-name {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #4CAF50;
    margin-bottom: 6px;
  }

.tennis-user-card .user-info-zip-code {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #4CAF50;
    margin-bottom: 6px;
  }

.tennis-user-card .user-info-email {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #4CAF50;
    margin-bottom: 6px;
  }
  
  
.tennis-user-card .user-info-skill-level {
    font-size: 16px;
    font-weight: bold;
    color: #4CAF50;
    margin-top: 10px;
  }

.tennis-user-card .user-info-preferences {
    font-size: 16px;
    font-weight: bold;
    color: #181b18;
    margin-top: 10px;
  }


.contact-info{
  font-weight: bold;
  color:#1b9dc9;
  }

/* below are the styling for user list after search in TennisUserList component*/

.tennis-user-list-container {
    display: flex;
    flex-direction:column;
    height: 800px; 
    overflow-y: auto;
}

.tennis-user-list-column {
  flex: 0 0 300px;
}

.text-background{
  background-color: #f1f1f1;
  margin: 5%;
}

.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
}



/* below are the styling for user sign-up form in NewUserForm component*/

.user-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}


.image-container {
  margin-bottom: 20px;
  position:absolute;
  display:flex;
  justify-content: flex-end;
  margin-bottom: 430px;
  margin-left:250px;
}

.tennis-image {
  max-width: 150px; 
  margin-left: 40px;
  margin-top: 70px;
}

.registration-form {
  background-color: #f3e595;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 400px;
  margin-top: 70px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #070807; 
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  color: #008080; 
}

input[type="text"],
input[type="email"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  resize: vertical;
}

.user-form-button[type="submit"] {

  background-color:#255b28;
  border: none;
  border-radius: 50px;
  padding: 14px 20px;
  font-size: 16px;
  color: white;
  cursor:pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-left: 120px;
}

.user-form-button[type="submit"]:hover {
  background-color: #268f9b; 
}


.success-message {
  margin-top: 180px;
  color:#020201;
  font-weight: bold;
  font-size:xx-large;
  margin-left: 500px;
  margin-bottom: 325px;
}

.error-message {
  margin-top: -30px;
  color:#020201;
  font-weight: bold;
  position:absolute;
  display:flex;
  margin-left:100px;
}


/* below are the styling for user info in Proile component*/

.h3-personal-profile {
  font-size: calc(1.3rem + .6vw);
  text-align: center;
  margin-right: 200px;
}

.no-personal-profile-msg{
  margin: 10%;
  margin-left: 20%;
  width:100%;
  position: center;
}

@media (max-width: 768px) {
  .profile-container {
    margin-left: 0;
  }
}

.profile-container {
  background-color: #f2f6a0;
  border-radius: 20px;
  padding: 1%;
  max-width: 400px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  text-align: center;
}

.profile-container img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-container h2 {
  color: #008080; 
  margin-left: 1%;
  margin-bottom: 10%;
}

.profile-container p {
  
  margin: 1%;
  text-align: left;

}

.profile-info {
  text-align: left;
  margin-top: -5%;
  margin-bottom: 20px;
  border-radius: 10px;
  border:2px solid #008080;
}



.profile-info p {
  position: relative; 
  margin-bottom: 15px; 
}

.profile-info p::before {
  content: "";
  position: absolute;
  top: -5px; 
  left: -10px; 
  width: 20px; 
  height: 20px; 
  border-radius: 5px; 
}

.profile-info p::after {
  content: "";
  position: absolute;
  bottom: -5px; 
  left: 0; 
  width: 100%; 
  height: 1px; 
  background-color: #008080;
}


.profile-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

}

.edit-btn {
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  background-color: #008080; 
  margin-top: 10px;
  margin-right: 100px;
  margin-bottom: 10px;
  margin-left: 100px;
}

.delete-btn {
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  background-color: #f13535; 
  margin-top: -100px;
  margin-bottom: -100px;
  margin-right:100px;
}

@media (max-width: 767px) { .profile-container { margin: 10px; }
.profile-container img { width: 120px; height: 120px; }
.profile-container h2 { margin-bottom: 5%; }}

.sign-up-button {
  background-color:#255b28;
  border: none;
  border-radius: 50px;
  padding: 14px 10px;
  font-size: 16px;
  color: white;
  cursor:pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-left: 90px;
}

.p-personal-profile {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: end;
  margin-right: 50%;
}


/* below code are for save change button after editing in EidtForm component. */
.save-change-button{
  padding:10px 20px;
  border-radius: 50px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  background-color: #008080;
  margin:20px;
  margin-top: 30px;
  margin-left: 150px;
  margin-bottom: 0px;
}


.footer-text-center{ 
  background-color:#000000;
  color:rgb(248, 245, 245);
  padding: 15px;
  height:150px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-top: 500px;
  
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add margins between the icons to create spacing */
.social-icons a {
  margin: 0 8px; /* Adjust the margin as needed */
}



/* google map */
.App {
  height: 40vh;
  width: 80vw;
}

.map-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  display:block;
}
.map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.map-info p {
  font-weight: bold;
}

@media (max-width: 767px) { .map-container { display: none; /* Hide the map container */ } }

